import React from "react"
import Layout from "../../../Components/pageComponents/contentContainer"
// import Img from "gatsby-image"
import Description from "../../../Components/pageComponents/bulletDescription"

export const query = graphql`
    query {
        banner: file(relativePath: { eq: "products/networking/application-and-network-performance/Application _ Netowrk Performance Banner.png" }) {
            childImageSharp {
                fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        mesh: file(relativePath: { eq: "products/networking/application-and-network-performance/Network Mesh Wifi Pub.png" }) {
            childImageSharp {
                fluid(maxWidth: 3000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

export default ({ data }) => {
    return (
        <Layout title="Application and Network Performance" image={data.banner.childImageSharp.fluid}>

            <Description heading="Products We Offer" title="Application and Network Performance" description="Application Performance Management (APM) is a practice within systems management that targets managing and tracking the availability and efficiency of software applications.
                    Network Performance Management (NPM) is the collective techniques that enable, manage and ensure optimal performance levels of the network.
                    With a complete Application and Network Performance Monitoring tool either software base or appliance base, a user can monitor and troubleshoot his/her Physical Layer up to the Application Layer of the Open System Interconnection (OSI) Model.">
                <div class="bg-gray ff-primary py-4 px-5">
                    <h4>
                        <ul>
                            <li>Load Balancers and Bandwidth Managers</li>
                            <li>Integrity Testing</li>
                            <li>Virus and Malicious Software Testing</li>
                            <li>Repeaters</li>
                        </ul>
                    </h4>
                </div>
            </Description>
        </Layout>
    )
}